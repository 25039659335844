<template>
<b-card v-if="hooks" no-body class="card-company-table">
    <b-table :items="hooks" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(targetBranch)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.targetBranch.name
        }}</span>
        </template>

        <template #cell(fromBranch)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.fromBranch.name
        }}</span>
        </template>

        <template #cell(actions)="data">
            <b-row>
                <b-col>
                    <b-button block variant="flat-warning" class="font-weight-bolder mb-6" @click="data.toggleDetails">DETAYLAR</b-button>
                </b-col>
                <b-col>
                    <b-button block variant="flat-primary" class="font-weight-bolder mb-6" :to="{ name: 'company-hooks-details-page', params: { uid: data.item.id,branchId:data.item.targetBranch.id } }">EKLE</b-button>
                </b-col>
                <b-col>
                    <b-button block variant="flat-success" class="font-weight-bolder mb-6" @click="runHook(data.item)">
                        {{getStatus(data.item.status)}}
                    </b-button>
                </b-col>
            </b-row>
        </template>

        <template #row-details="data">
            <b-table :items="data.item.details" responsive :fields="detailsFields" class="mb-0">
                <template #cell(idNumber)>
                    <b-button block variant="flat-primary" class="font-weight-bolder mb-12">DETAYLAR</b-button>
                </template>
                <template #cell(status)="data">
                    <span>{{getStatus(data.item.status)}}</span>
                </template>
            </b-table>
        </template>

    </b-table>
    <!--Open Add Branch-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Senkronizasyon Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kaynak Şube ?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(branch) => branch.id" :options="branchs" v-model="addHookRequest.targetBranch" />
                </b-form-group>
                <b-form-group label="Aktarılan Şube ?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(branch) => branch.id" :options="branchs" v-model="addHookRequest.fromBranch" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addBranch">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        BRow,
        BCol,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'targetBranch', label: 'Kaynak Şube' },
                { key: 'fromBranch', label: 'Aktarılan Şube' },
                { key: 'actions', label: 'İşlemler' },
            ],
            hooks: [],
            branchs: [],
            addPanel: false,
            addHookRequest: {
                targetBranch: '',
                fromBranch: ''
            },
            detailsFields: [
                { key: 'createdDate', label: 'Kayıt Tarihi' },
                { key: 'isSync', label: 'Sekronizasyon Durumu' },
                { key: 'className', label: 'Sınıf' },
                { key: 'lessonName', label: 'Ders' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'status', label: 'Senkronizasyon Durumu' },
            ]
        }
    },
    created() {
        this.getData();
        this.getBranch();
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            //console.log("Add Button Call");
            this.addPanel = true;
        });
    },
    methods: {
        getStatus(status) {
            switch (status) {
                case "Waiting":
                    return "Başlat";
                case "Queu":
                    return "Sıraya Alındı";
                case "Running":
                    return "Senkronize Ediliyor";
                case "Finish":
                    return "Senkronize Edildi";
            }
        },
        async getData() {
            var hooks = await this.$http.get("Company/Hooks");
            this.hooks = hooks.data.data;
        },
        async getBranch() {
            var branchs = await this.$http.get("Company/Branchs");
            this.branchs = branchs.data.data;
        },
        async runHook(data) {
            if (data.status == 'Waiting') {
                var hookStatus = await this.$http.get("Company/RunHook/" + data.id);
                this.getData();
            }
        },
        async addBranch() {
            this.$http.post("Company/AddHook", this.addHookRequest).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Bildirim',
                        icon: 'BellIcon',
                        text: 'İşlem Başarılı.',
                        variant: 'success'
                    },
                });
                this.addPanel = false;
                this.getData();
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Bildirim',
                        icon: 'BellIcon',
                        text: error.response.data.error.message,
                        variant: 'danger'
                    },
                });
            });

        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
